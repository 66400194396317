import React, { useEffect, useState } from 'react';
import { API } from 'api/API';
import regionList from 'data/regionList';
import DropdownSelector from 'component/DropdownSelector/DropdownSelector';

const CitySelector = ({
  startRegion,
  startCity,
  onSelect = (region, v) => console.log(region, v),
}) => {
  const [region, setRegion] = useState(startRegion);
  const [cityOptions, setCityOptions] = useState([]);

  const { data: cityData } = API.useGetCities({ regionCode: region });

  const onRegionChange = v => {
    setRegion(v.label);
  };

  useEffect(() => {
    if (cityData) {
      if (cityData?.data?.cities?.length <= 0) {
        alert('해당 지역에는 체육관이 없습니다.');
        setRegion(startRegion);
      } else {
        setCityOptions(
          cityData?.data?.cities?.map(v => ({ label: v.name, value: v._id })),
        );
        onSelect(region, {
          label: cityData?.data?.cities[0].name,
          value: cityData?.data?.cities[0]._id,
        });
      }
    }
  }, [cityData, startRegion, region]);

  return (
    <div className="flex flex-row">
      <DropdownSelector
        options={regionList}
        value={regionList.find(v => v.label === region)}
        onChange={onRegionChange}
      />
      <DropdownSelector
        className="ml-3"
        options={cityOptions}
        value={cityOptions?.find(v => v.value === startCity)}
        onChange={v => onSelect(region, v)}
      />
    </div>
  );
};

export default CitySelector;
