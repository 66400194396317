import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'api/API';
import CitySelector from 'component/CitySelector/CitySelector';
import regionList from 'data/regionList';

const typeName = {
  class: '수업형',
  free: '자유형',
};

const GymList = () => {
  const [regionCity, setRegionCity] = useState({
    region: regionList[0].label,
    city: '',
  });
  const [gymList, setGymList] = useState([]);

  const { data: gymData } = API.useGetGyms({
    cityId: regionCity.city,
    regionCode: regionCity.region,
  });

  const onCitySelect = (region, v) => {
    setRegionCity({ region, city: v.value });
  };

  useEffect(() => {
    if (gymData && regionCity.city && regionCity.region) {
      if (gymData?.data?.gyms.length <= 0) {
        alert('해당 지역에는 체육관이 없습니다.');
      } else {
        setGymList(gymData.data.gyms);
      }
    }
  }, [gymData]);

  return (
    <div className="mx-auto w-full max-w-[40rem] flex flex-col px-5 lg:px-0">
      <CitySelector
        startRegion={regionCity.region}
        startCity={regionCity.city}
        onSelect={onCitySelect}
      />
      <div className="mt-5 w-full max-w-[40rem] grid grid-cols-2 gap-8">
        {gymList.map(item => (
          <Link to={`/info/${item._id}`} key={item._id}>
            <div className="w-full relative">
              <img
                src={item.mainBanner}
                alt={item.name}
                className="w-full h-full object-cover rounded-lg border-2 border-stone-300 mb-2"
              />
              <div className="text-black text-base font-bold mb-1">
                {item.name}
              </div>
              <div className="text-zinc-500 text-xs font-normal mb-1">
                {item.address} · {typeName[item.type]} {item.category.name}
              </div>
              <div className="text-black text-sm font-semibold">
                {item.dayPoint + item.gymFee}캐쉬/1회
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default GymList;
