import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlayView = autoPlay(SwipeableViews);

const SlideView = ({ renderer, data }) => {
  const [newsPage, setNewsPage] = useState(0);

  return (
    <div className="relative">
      <AutoPlayView
        className=""
        index={newsPage}
        onChangeIndex={index => setNewsPage(index)}
        enableMouseEvents
      >
        {data.map((item, index) => renderer(item, index))}
      </AutoPlayView>
      <div className="absolute flex bottom-4 right-[10%]">
        {data.map((item, index) => (
          <button
            key={index}
            onClick={() => setNewsPage(index)}
            className={`${
              newsPage === index ? 'bg-white3' : 'bg-white2'
            } rounded-full w-2 h-2 mx-1 hover:opacity-80`}
          />
        ))}
      </div>
    </div>
  );
};

export default SlideView;
